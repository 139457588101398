import {
  USER_LOGIN,
  USER_LOGOUT,
  ADD_NUMBER,
  LOAD_ALL_NUMBERS,
  DELETE_NUMBER,
} from './actions';

export const reducer = (state, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        loggedUser: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        isNumbersFetched: false,
        loggedUser: {},
      };
    case ADD_NUMBER:
      return { ...state, numbers: [...state.numbers, action.payload] };
    case LOAD_ALL_NUMBERS:
      return {
        ...state,
        isNumbersFetched: true,
        numbers: [...action.payload],
      };
    case DELETE_NUMBER:
      return {
        ...state,
        numbers: [
          ...state.numbers.filter((number) => number._id !== action.payload),
        ],
      };
    default:
      return state;
  }
};
