import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useStateValue } from '../context/stateContext';
import { Image, Dropdown } from 'semantic-ui-react';
import { login, logout } from '../context/actions';

const Login = () => {
  const [{ loggedUser }, dispatch] = useStateValue();

  const onLoginSuccess = async ({ credential }) => {
    const profileObject = jwtDecode(credential);

    const userData = {
      isLoggedIn: true,
      googleId: profileObject.nbf,
      name: profileObject.name,
      token: credential,
      imageUrl: profileObject.picture,
      email: profileObject.email,
    };

    dispatch(await login(userData));
  };

  const onLoginError = (googleEvent) => {
    console.error(JSON.stringify(googleEvent));
  };

  const trigger = <Image avatar src={loggedUser.imageUrl} />;

  const option = [
    {
      key: 'sign-out',
      text: 'Sign Out',
      icon: 'sign out',
      onClick: () => dispatch(logout()),
    },
  ];

  return (
    <>
      {!loggedUser.isLoggedIn ? (
        <GoogleLogin
          shape="circle"
          outline="filled_blue"
          text="signin"
          width="50"
          onSuccess={onLoginSuccess}
          onError={onLoginError}
        />
      ) : (
        <Dropdown
          trigger={trigger}
          options={option}
          pointing="top right"
          icon={null}
          className="logout-dropdown"
        />
      )}
    </>
  );
};

export default Login;
