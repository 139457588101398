import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import TrackInput from './TrackInput';
import TrackResults from './TrackResults';
import TrackMessage from './TrackMessage';
import { useStateValue } from '../context/stateContext';
import { TRACK_API_ENDPOINT } from './../const/endpint';
import Heading from './Heading';

const TrackPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const redirect = location.search
  //   ? location.search.split('=')[1]
  //   : '/';
  const { number } = queryString.parse(location.search);
  const [isLoading, setLoading] = useState(false);
  const [trackingResults, setTrackingResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState(
    'Please input tracking number and click "Track" button!',
  );
  const [{ loggedUser }] = useStateValue();

  const handleSubmit = (trackingNumber) => {
    setLoading(true);
    navigate({ search: `?number=${trackingNumber}` });
    axios
      .get(`${TRACK_API_ENDPOINT}?number=${trackingNumber}`)
      .then((response) => {
        setLoading(false);
        setTrackingResults(response.data);
      })
      .catch(() => {
        setErrorMessage(
          `Tracking data is not found for the number "${trackingNumber}"`,
        );
        setTrackingResults({});
        setLoading(false);
      });
  };

  return (
    <div className="tracking-app">
      <div className="header-container">
        <Heading />
        <div
          className={
            loggedUser.isLoggedIn
              ? 'track-input-container'
              : 'track-input-container-not-logged'
          }
        >
          <TrackInput
            onSubmit={handleSubmit}
            isLoading={isLoading}
            number={number}
          />
        </div>
      </div>
      <div className="track-page-container">
        {!!Object.keys(trackingResults).length && (
          <TrackResults trackingResults={trackingResults} />
        )}
        {!Object.keys(trackingResults).length && errorMessage && (
          <TrackMessage message={errorMessage} />
        )}
      </div>
    </div>
  );
};

export default TrackPage;
