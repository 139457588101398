import React, { useState, useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ModalNumberList from './ModalNumberList';
import ModalNumbersAdd from './ModalNumbersAdd';
import { useStateValue } from '../context/stateContext';
import { filterTrackingNumberInput } from '../utility/inputFilter';

const TrackInput = ({ isLoading, onSubmit, number }) => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [isListModalOpen, setListModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [{ loggedUser }] = useStateValue();

  useEffect(() => {
    if (number) {
      requestSearch(number);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = ({ target: { value } }) => {
    setTrackingNumber(filterTrackingNumberInput(value));
  };

  const requestSearch = (number) => {
    setTrackingNumber(number);
    onSubmit(number);
    handleListModalClose();
    hangleAddModalClose();
  };

  const handleListModalClose = () => setListModalOpen(false);
  const hangleAddModalClose = () => setAddModalOpen(false);

  return (
    <>
      <Form onSubmit={() => requestSearch(trackingNumber)}>
        <Form.Group>
          <Form.Input
            className="input-number"
            size="small"
            placeholder="Tracking number"
            name="trackingNumber"
            value={trackingNumber}
            onChange={handleChange}
          />
          <Form.Button
            content="Track"
            size="small"
            disabled={!trackingNumber || trackingNumber.length < 13}
            loading={isLoading}
          />
          {loggedUser.isLoggedIn && (
            <>
              <Button
                circular
                basic
                icon="list"
                type="button"
                onClick={() => setListModalOpen(true)}
              />
              <Button
                circular
                basic
                type="button"
                icon="plus"
                onClick={() => setAddModalOpen(true)}
              />
            </>
          )}
        </Form.Group>
      </Form>
      <ModalNumberList
        isOpen={isListModalOpen}
        handleClose={handleListModalClose}
        handleSelectNumber={requestSearch}
      />
      <ModalNumbersAdd
        isOpen={isAddModalOpen}
        handleClose={hangleAddModalClose}
        trackNumber={requestSearch}
      />
    </>
  );
};

TrackInput.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  number: PropTypes.string,
};

export default TrackInput;
