import ls from 'local-storage';

const CACHE_NAME = 'trackingCache';

const get = (key) => {
  const cache = JSON.parse(ls.get(CACHE_NAME));
  return cache && cache.get ? cache.get[key] : undefined;
};

const set = (key, data, ttl) => {
  let cache = JSON.parse(ls.get(CACHE_NAME));
  if (!cache || !cache.get) {
    cache = {
      get: {},
    };
  }
  cache.get[key] = {
    data,
    ttl,
    savedTime: new Date().getTime(),
  };
  ls.set(CACHE_NAME, JSON.stringify(cache));
};

const remove = () => ls.remove(CACHE_NAME);

export default {
  get,
  set,
  remove,
};
