import axios from 'axios';
import cache from './cache';
import { getLocalUserData } from './localUser';

const CACHE_VALID_IN_MS = 3600000;
const cacheEnabled = true;

if (!cacheEnabled) {
  cache.remove();
}

axios.interceptors.request.use(
  (request) => {
    setRequestAuthentication(request);
    getCachedResultsForGetRequests(request);
    return request;
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    setCachedResultForGetResponse(response);
    return response;
  },
  (error) => Promise.reject(error),
);

const setRequestAuthentication = (request) => {
  const loggedUser = getLocalUserData();
  if (loggedUser && loggedUser.email) {
    request.headers.auth = loggedUser.email;
  }
};

const getCachedResultsForGetRequests = (request) => {
  const { method, url } = request;
  const { onLine } = navigator;

  if (cacheEnabled && method === 'get') {
    const _cached = cache.get(url);

    if (_cached && (isCacheValid(_cached) || !onLine)) {
      _cached.data.__fromCache = true;

      request.data = _cached.data;
      request.adapter = () => {
        return Promise.resolve({
          data: _cached.data,
          status: request.status,
          statusText: request.statusText,
          headers: request.headers,
          config: request,
          request: request,
        });
      };
    }
  }
};

const isCacheValid = (cachedData) => {
  const { savedTime, ttl } = cachedData;
  return !ttl || new Date().getTime() - savedTime <= ttl;
};

const setCachedResultForGetResponse = (response) => {
  const {
    data,
    config: { method, url },
  } = response;
  const { onLine } = navigator;

  if (cacheEnabled && method === 'get' && !data.__fromCache) {
    cache.set(url, data, CACHE_VALID_IN_MS);
  }

  if (method !== 'get' && onLine) {
    cache.remove();
  }
};

export default axios;
