import React, { useState } from 'react';
import { Modal, Button, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useStateValue } from '../context/stateContext';
import { addNumber } from '../context/actions';
import { filterTrackingNumberInput } from '../utility/inputFilter';

const initialNumber = { name: '', number: '' };

const ModalNumbersAdd = ({ isOpen, handleClose, trackNumber }) => {
  const dispatch = useStateValue()[1];
  const [newNumber, setNewNumber] = useState(initialNumber);

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'number') {
      value = filterTrackingNumberInput(value);
    }
    setNewNumber({
      ...newNumber,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (newNumber.number) {
      trackNumber(newNumber.number);
    }
    try {
      dispatch(await addNumber(newNumber));
      setNewNumber(initialNumber);
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseModal = () => {
    handleClose();
    setNewNumber(initialNumber);
  };

  const handleNumberError = (name) =>
    (name && name.length < 13) || (name && name.length > 13)
      ? 'Tracking number must contain 13 characters'
      : false;
  const handleNumberNameError = (number) =>
    number && number.length < 3 ? 'Please enter at least 3 characthers' : false;

  return (
    <div>
      <Modal
        size="mini"
        dimmer="blurring"
        open={isOpen}
        onClose={handleCloseModal}
      >
        <Modal.Header>Add new tracking number:</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              error={handleNumberNameError(newNumber.name)}
              label="Name of the tracknig number"
              required
              name="name"
              placeholder="Name"
              value={newNumber.name}
              onChange={handleChange}
            />
            <Form.Input
              error={handleNumberError(newNumber.number)}
              label="Tracking Number"
              required
              name="number"
              placeholder="Number"
              value={newNumber.number}
              onChange={handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={handleCloseModal}>
            {' '}
            Close
          </Button>

          <Button
            disabled={
              !!handleNumberError(newNumber.number) ||
              !!handleNumberNameError(newNumber.name) ||
              !newNumber.name ||
              !newNumber.number
            }
            onClick={handleSubmit}
            type="submit"
          >
            {' '}
            Add
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

ModalNumbersAdd.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  trackNumber: PropTypes.func,
};

export default ModalNumbersAdd;
