import React, { useEffect } from 'react';
import {
  Button,
  Modal,
  Segment,
  Loader,
  Image,
  List,
  Item,
  Dimmer,
} from 'semantic-ui-react';
import loaderImage from '../assets/loader-paragraph.png';
import PropTypes from 'prop-types';
import { useStateValue } from '../context/stateContext';
import { loadAllNumbers, deleteNumber } from '../context/actions';

const ModalNumberList = ({ isOpen, handleClose, handleSelectNumber }) => {
  const [{ numbers, isNumbersFetched }, dispatch] = useStateValue();

  useEffect(() => {
    if (isOpen) {
      showAllNumbers();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const showAllNumbers = async () => {
    if (!isNumbersFetched || !numbers.length) {
      dispatch(await loadAllNumbers());
    }
  };

  return (
    <div>
      <Modal
        className="track-numner-list-modal"
        dimmer="blurring"
        open={isOpen}
        onClose={handleClose}
      >
        <Modal.Header>
          <div>List of your tracking numbers:</div>
          <span className="track-number-info">
            (please click/tap on the number to see tracking message)
          </span>
        </Modal.Header>
        <Modal.Content>
          {!isNumbersFetched && (
            <Segment>
              <Dimmer active inverted>
                <Loader active inverted>
                  Loading Numbers
                </Loader>
              </Dimmer>
              <Image src={loaderImage} />
            </Segment>
          )}
          {isNumbersFetched && (
            <List divided>
              {numbers.map((number) => (
                <Item className="list-row-padding" key={number._id}>
                  <span
                    className="number-list-section"
                    onClick={() => handleSelectNumber(number.number)}
                  >
                    {number.name} - <b>{number.number}</b>
                  </span>
                  {!number.disabled && (
                    <span
                      className="delete-number-button"
                      onClick={() => dispatch(deleteNumber(number._id))}
                    >
                      <Button
                        circular
                        basic
                        size="mini"
                        icon="trash alternate outline"
                        color="red"
                      />
                    </span>
                  )}
                </Item>
              ))}
            </List>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={handleClose}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

ModalNumberList.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSelectNumber: PropTypes.func,
};

export default ModalNumberList;
