import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import TrackPage from './components/TrackPage';
import { StateProvider } from './context/stateContext';
import { initLoggedUser, getLocalUserData } from './utility/localUser';
import { reducer } from './context/reducer';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const AppWithRouting = () => (
  <Router>
    <Routes>
      <Route path="/" element={<TrackPage />}></Route>
    </Routes>
  </Router>
);

const App = () => {
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <StateProvider
        initialState={{
          loggedUser: {
            ...initLoggedUser,
            ...getLocalUserData(),
          },
          isNumbersFetched: false,
          numbers: [],
        }}
        reducer={reducer}
      >
        <AppWithRouting />
      </StateProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
