import axios from '../utility/axios';
import { LOGIN_ENDPOINT, NUMBERS_API_ENDPOINT } from '../const/endpint';
import { saveLocalUserData, removeLocalUserData } from '../utility/localUser';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const ADD_NUMBER = 'ADD_NUMBER';
export const LOAD_ALL_NUMBERS = 'LOAD_ALL_NUMBERS';
export const DELETE_NUMBER = 'DELETE_NUMBER';

const exampleTrackingNumber = {
  _id: 12345,
  name: 'Example Tracking Number',
  number: 'CY361749334US',
  disabled: true,
};

export const login = async (userData) => {
  await axios.post(LOGIN_ENDPOINT, userData);
  saveLocalUserData(userData);
  return {
    type: USER_LOGIN,
    payload: userData,
  };
};

export const logout = () => {
  removeLocalUserData();
  return {
    type: USER_LOGOUT,
  };
};

export const loadAllNumbers = async () => {
  const { data } = await axios.get(NUMBERS_API_ENDPOINT);
  const trackingNumbers = data && data.length ? data : [exampleTrackingNumber];
  return {
    type: LOAD_ALL_NUMBERS,
    payload: trackingNumbers,
  };
};

export const addNumber = async (newNumber) => {
  const numberResponse = await axios.put(NUMBERS_API_ENDPOINT, newNumber);
  const savedNumber = numberResponse.data.ops[0];
  return {
    type: ADD_NUMBER,
    payload: savedNumber,
  };
};

export const deleteNumber = (numberId) => {
  axios.delete(`${NUMBERS_API_ENDPOINT}/${numberId}`);
  return {
    type: DELETE_NUMBER,
    payload: numberId,
  };
};
