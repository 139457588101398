import React from 'react';
import PropTypes from 'prop-types';
import TrackResultTable from './TrackResultTable';

const TrackResults = ({ trackingResults }) => {
  const { tarckingResultBihPoste, trackingReultPosteSrpske } = trackingResults;

  return (
    <>
      {tarckingResultBihPoste && tarckingResultBihPoste.length && (
        <TrackResultTable
          serviceName={'bhPosta'}
          trackingResults={tarckingResultBihPoste}
        />
      )}

      {trackingReultPosteSrpske && trackingReultPosteSrpske.length && (
        <TrackResultTable
          serviceName={'posteSrpske'}
          trackingResults={trackingReultPosteSrpske}
        />
      )}
    </>
  );
};

TrackResults.propTypes = {
  trackingResults: PropTypes.object.isRequired,
};

export default TrackResults;
