import React, { useEffect, useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import Login from './Login';

const Heading = () => {
  const isNavigatorOnline = !!navigator.onLine;
  const [isOnline, setOnline] = useState(isNavigatorOnline);

  useEffect(() => {
    const handleOnlineStatus = ({ type }) => {
      setOnline(type === 'online');
    };

    window.addEventListener('offline', handleOnlineStatus);
    window.addEventListener('online', handleOnlineStatus);

    return () => {
      window.removeEventListener('offline');
      window.removeEventListener('online');
    };
  }, []);

  return (
    <div>
      <Segment
        className="remove-header-radius"
        color={isOnline ? 'grey' : 'red'}
        inverted
        clearing
      >
        {isOnline ? (
          <Header as="h3" color="black" floated="left" className="margin-0">
            BIH parcel tracking.
          </Header>
        ) : (
          <Header
            as="h3"
            textAlign="center"
            floated="left"
            className="margin-0"
          >
            You are in offline mode!!!
          </Header>
        )}
        <Header as="h3" color="black" floated="right" className="margin-0">
          <Login />
        </Header>
      </Segment>
    </div>
  );
};

export default Heading;
