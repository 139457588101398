import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const segmantSyle = {
  textAlign: 'center',
  minHeight: '100px',
  backGroundColor: '#f9fafb',
};

const TrackMessage = ({ message }) => (
  <Segment placeholder style={segmantSyle}>
    <Header>{message}</Header>
  </Segment>
);

TrackMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default TrackMessage;
