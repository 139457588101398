import ls from 'local-storage';

export const initLoggedUser = {
  isLoggedIn: false,
  googleId: '',
  name: '',
  email: '',
  token: '',
  imageUrl: '',
};

const USER_DATA = 'userData';
export const getLocalUserData = () => ls.get(USER_DATA);
export const saveLocalUserData = (userData) => ls.set(USER_DATA, userData);
export const removeLocalUserData = () => ls.set(USER_DATA, {});
