import React from 'react';
import { Table, Segment, Label, Image } from 'semantic-ui-react';
import Media from 'react-media';
import PropTypes from 'prop-types';
import TrackResultRow from './TrackResultRow';
import bhPostaLogo from '../assets/bhPostaLogo.png';
import posteSrpskeLogo from '../assets/posteSrpskeLogo.png';

const TrackResultTable = ({ serviceName, trackingResults }) => {
  let trackingService = {};
  switch (serviceName) {
    case 'bhPosta':
      trackingService = {
        logo: bhPostaLogo,
        url: 'https://www.posta.ba/',
        shortName: 'bhPosta',
      };
      break;
    case 'posteSrpske':
      trackingService = {
        logo: posteSrpskeLogo,
        url: 'http://www.postesrpske.com/',
        shortName: 'posteSrpske',
      };
      break;
    default:
  }
  return (
    <>
      <Segment className={trackingService.shortName} raised>
        <Label ribbon>
          <Image
            className="service-logo"
            src={trackingService.logo}
            as="a"
            href={trackingService.url}
            target="_blank"
          />
        </Label>
        <Table celled padded>
          <Media
            query="(min-width: 768px)"
            render={() => (
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Message</Table.HeaderCell>
                  <Table.HeaderCell>Location</Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            )}
          />
          <Table.Body>
            {trackingResults.map((trackingResult, $index) => (
              <TrackResultRow trackingResult={trackingResult} key={$index} />
            ))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};

TrackResultTable.propTypes = {
  serviceName: PropTypes.string.isRequired,
  trackingResults: PropTypes.array.isRequired,
};

export default TrackResultTable;
